import { Injectable } from '@angular/core';
import { combineLatest, interval } from 'rxjs';
import { concatMap, map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from '@kildenconfig/api-config';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private nib_project_ticket: string | undefined;
  private nib_ticket: string | undefined;
  private lantmeteriet_ticket: string | undefined;
  constructor(private _http: HttpClient) {}

  public getTickets() {
    const ticketInt = 3000000;
    const defaultOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        'max-age': '0',
      },
    };
    const token = interval(ticketInt).pipe(
      startWith(0),
      concatMap(() =>
        this._http.get(ApiConfig.getTokenUrl, defaultOptions).pipe(
          map((res: any) => {
            if (!res) {
              return null;
            } else {
              return res['token'];
            }
          })
        )
      )
    );

    const ticket_nib = interval(ticketInt).pipe(
      startWith(0),
      concatMap(() =>
        this._http.get(ApiConfig.ticket_nib, defaultOptions).pipe(
          map((res: { [key: string]: any }) => {
            if (!res) return null;

            this.nib_ticket = res['nib_ticket'];
            return res;
          })
        )
      )
    );

    const ticket_nib_project = interval(ticketInt).pipe(
      startWith(0),
      concatMap(() =>
        this._http.get(ApiConfig.ticket_nib_project, defaultOptions).pipe(
          map((res: { [key: string]: any }) => {
            if (!res) return null;

            this.nib_project_ticket = res['nib_project_ticket'];
            return res;
          })
        )
      )
    );

    const ticket_lantmeteriet = interval(ticketInt).pipe(
      startWith(0),
      concatMap(() =>
        this._http.get(ApiConfig.ticket_lantmeteriet, defaultOptions).pipe(
          map((res: { [key: string]: any }) => {
            if (!res) return null;

            this.lantmeteriet_ticket = res['ticket'];
            // console.log('this.lantmeteriet_ticket: ', this.lantmeteriet_ticket);
            return res;
          })
        )
      )
    );

    return combineLatest([token, ticket_nib_project, ticket_nib, ticket_lantmeteriet]);
  }

  getProjectTickets() {
    return [this.nib_ticket, this.nib_project_ticket];
  }

  getLantmeterietTicket() {
    return this.lantmeteriet_ticket;
  }
}
